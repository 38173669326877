/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fetchMessages } from './fetchIntialMessages';
import {
  handleOutgoingAudioCall,
  handleEndCall,
  handleMuteToggle,
  handleAcceptCall,
  handleDeclineCall,
  handleIncomingAudioCall
} from './handleAudioCall';
import { handleFileChange, handleFileUpload } from './handleFileUpload';
import { handleSendMessage, sendMessageOnEnter } from './handleSendMessage';
import { loadOlderMessages } from './loadOlderMessages';
import { MATRIX_SERVER_URL } from '../url';
import { getPublicRooms } from './fetchPublicRooms';
import { getDmsPersons } from './fetchDms';
import { getRooms } from './fetchRooms';
import { fetchUserProfile } from './fetchUserProfile';
import {
  handleDirectMessageRoom,
  joinRoomById,
  sendInitialMessage,
  updateDirectMessages
} from './sendDm';
import { restart } from './restart';
import { calculateUnreadCount, sendReadReceipt } from './readUnread';
import { getReadByUsers } from './getReadByUsers';

// Define a function to construct media URLs
const constructMediaUrl = (url: string): string | null => {
  if (url) {
    const parts = url.split('/');
    if (parts.length >= 4) {
      return `${MATRIX_SERVER_URL}/_matrix/media/r0/download/${parts[2]}/${parts[3]}`;
    }
  }
  return null;
};

export {
  handleIncomingAudioCall,
  handleAcceptCall,
  handleDeclineCall,
  handleSendMessage,
  handleFileUpload,
  handleFileChange,
  constructMediaUrl,
  handleOutgoingAudioCall,
  handleEndCall,
  loadOlderMessages,
  handleMuteToggle,
  fetchMessages,
  getPublicRooms,
  getDmsPersons,
  getRooms,
  fetchUserProfile,
  sendInitialMessage,
  updateDirectMessages,
  joinRoomById,
  handleDirectMessageRoom,
  restart,
  sendMessageOnEnter,
  sendReadReceipt,
  calculateUnreadCount,
  getReadByUsers
};
