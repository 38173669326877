import { useState, useCallback } from 'react';
import { MatrixClient } from 'matrix-js-sdk'; // Adjust the import as necessary
import { getPublicRooms } from '../pages/Chat/helper';
import { RoomData } from '../pages/Chat/types';

export const useFetchPublicRooms = () => {
  const [publicRooms, setPublicRooms] = useState<RoomData[] | never>([]); // State to hold public rooms

  const fetchPublicRooms = useCallback(async (client: MatrixClient, searchQuery: string) => {
    try {
      const rooms = await getPublicRooms(client, searchQuery);
      setPublicRooms(rooms as RoomData[]); // Update state with fetched rooms
    } catch (error) {
      console.error('Error fetching public rooms:', error);
    }
  }, []);

  // Return publicRooms and the fetch function from the hook
  return { publicRooms, fetchPublicRooms, setPublicRooms };
};
