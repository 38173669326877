import { MatrixClient } from 'matrix-js-sdk';
import { RoomData } from '../types';

export const getDmsPersons = async (client: MatrixClient | null) => {
  if (client) {
    const directChats = client.getAccountData('m.direct');
    if (directChats) {
      const dmPersonsList: RoomData[] = [];

      // Iterate over each user in the direct chat mapping
      const directChatsContent = directChats.getContent();
      await Promise.all(
        Object.entries(directChatsContent).map(async ([userId, roomIds]) => {
          // Find the first room where the user has "joined"
          await Promise.all(
            roomIds.map(async (roomId: string | undefined) => {
              const room = client.getRoom(roomId);
              if (room) {
                const member = room.getMember(userId);
                if (member && member.membership === 'join') {
                  // Fetch display name, presence status, and avatar URL
                  const displayName = member.name || userId;
                  const presence = member.user?.presence || 'offline'; // Default to offline
                  const avatarUrl = member.user?.avatarUrl
                    ? client.mxcUrlToHttp(member.user.avatarUrl)
                    : null;

                  // Avoid duplicates (if user is part of multiple DM rooms)
                  if (!dmPersonsList.find((person) => person.roomId === roomId)) {
                    dmPersonsList.push({
                      userId,
                      roomId: roomId as string,
                      name: displayName as string,
                      presence: presence as string, // Add presence
                      avatarUrl: avatarUrl as string, // Add avatar URL
                      isDm: true
                    });
                  }
                }
              }
            })
          );
        })
      );

      return dmPersonsList;
    }
  }
  return [];
};
