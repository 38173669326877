/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  createClient,
  MatrixClient,
  SyncState,
  MatrixEvent,
  EventTimeline,
  MatrixCall,
  EmittedEvents,
  RoomMemberEvent,
  ReceiptType,
  MsgType,
  Room
} from 'matrix-js-sdk';
import {
  constructMediaUrl,
  fetchUserProfile,
  getReadByUsers,
  handleDirectMessageRoom,
  joinRoomById,
  loadOlderMessages,
  restart,
  sendReadReceipt
} from '../pages/Chat/helper';
import { RoomData, MessageData, LoadState, Messages } from '../pages/Chat/types';
import { MATRIX_SERVER_URL } from '../pages/Chat/url';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilteredRooms, useFetchInvitations, useFetchRooms, useFetchPublicRooms } from '.';

const useMatrix = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const token = urlParams.get('token') || localStorage.getItem('matrixAccessToken');
  const deviceId = urlParams.get('deviceId') || localStorage.getItem('matrixDeviceId');
  const userId = urlParams.get('userId') || localStorage.getItem('matrixUserId');

  const { t } = useTranslation();
  const [client, setClient] = useState<MatrixClient | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [showInvitations, setShowInvitations] = useState<boolean>(false);
  const [showRooms, setShowRooms] = useState<boolean>(false);
  const [selectedRoom, setSelectedRoom] = useState<RoomData | undefined>();
  const [showPersons, setShowPersons] = useState<boolean>(false);
  const [messages, setMessages] = useState<Messages[]>([]);
  const [lastMessageId, setLastMessageId] = useState<string | null>(null);
  const [message, setMessage] = useState('');
  const [isCalling, setIsCalling] = useState(false);
  const [isCallConnected, setCallConnected] = useState(false);
  const [callTime, setCallTime] = useState<string | number>('00:00');
  const [isMuted, setIsMuted] = useState(false);
  const [incomingCall, setIncomingCall] = useState<MatrixCall | null>(null);
  const [activeCall, setActiveCall] = useState<MatrixCall | null>(null);
  const [loadState, setLoadState] = useState<LoadState>({});
  const [messagesFetched, setMessagesFetched] = useState<boolean>(false); // Track if messages are fetched
  const [isLoadMoreProcessing, setLoadMoreProcessing] = useState<boolean>(false); // Track if messages are fetched

  const [isLoadingEarlier, setIsLoadingEarlier] = useState(false);

  const [typingPerson, setTypingPerson] = useState('');

  //rooms, people, invitations
  const [searchQuery, setSearchQuery] = useState<string>('');

  // Use the custom hook to fetch rooms and persons
  const { rooms, persons, fetchRooms, fetchSelectedRoom } = useFetchRooms();

  const { publicRooms, setPublicRooms, fetchPublicRooms } = useFetchPublicRooms();
  const { invitations, setInvitations, fetchInvitations } = useFetchInvitations();
  const { filteredRooms, filteredPersons, setFilteredRooms } = useFilteredRooms(
    client,
    setPublicRooms,
    searchQuery,
    rooms,
    persons
  );

  //search
  const [alert, setAlert] = useState<{
    open: boolean;
    severity: 'success' | 'error' | 'info';
    message: string;
  }>({
    open: false,
    severity: 'success',
    message: ''
  }); // AlertSnackbar state

  const timeline = useRef<EventTimeline | null>(null);
  const isMessageLoading = !messagesFetched && !isLoadMoreProcessing;

  // Effect to handle message events
  useEffect(() => {
    if (client && selectedRoom) {
      // Set up event listeners
      client.on('Room.timeline' as EmittedEvents, handleIncomingMessage);
      client.on('Room.receipt' as EmittedEvents, handleReadReceipts);

      // Fetch messages initially, but only if not already fetched
      if (isMessageLoading) {
        setMessages([]);
        loadOlderMessages(
          setLastMessageId,
          setMessages,
          updateCanLoadMore,
          setChatLoading,
          client,
          selectedRoom as RoomData,
          timeline
        ).then(async () => {
          if (client) {
            // Fetch the room from the client
            const matrixRoom = client.getRoom(selectedRoom.roomId);

            if (matrixRoom && selectedRoom?.roomId) {
              // Iterate through the timeline events to check for unread messages
              const liveTimeline = matrixRoom.getLiveTimeline(); // Replaces `room.timeline`
              const events = liveTimeline.getEvents(); // Fetches events in the live timeline
              for (const event of events) {
                if (
                  event.getType() === 'm.room.message' &&
                  event.getSender() !== client.getUserId()
                ) {
                  try {
                    // Send a read receipt for each unread message
                    await client.sendReadReceipt(event, ReceiptType.Read);
                    const latestEvent = events[events.length - 1]; // Get the latest event in the room

                    if (latestEvent) {
                      client.sendReadReceipt(latestEvent); // Send a read receipt for the latest event
                    }
                    fetchSelectedRoom(client as MatrixClient, selectedRoom?.roomId);
                  } catch (error) {
                    console.error('Failed to send read receipt:', error);
                  }
                }
              }
            }
          }
          setMessagesFetched(true); // Set the flag after messages have been fetched
        });
      }

      // Cleanup event listeners on unmount
      return () => {
        client.off('Room.timeline' as EmittedEvents, handleIncomingMessage);
        client.off('Room.receipt' as EmittedEvents, handleReadReceipts); // Cleanup read receipt listener as well
      };
    }
  }, [client, selectedRoom, invitations]); // dependencies array

  // Effect to initialize the Matrix client
  useEffect(() => {
    if (!token || token === 'null') {
      setLoading(false);
      navigate('/');
      return;
    }

    initializeMatrixClient();
  }, [token, deviceId, userId, selectedRoom, fetchRooms, fetchInvitations, fetchPublicRooms]);

  const handleIncomingMessage = async (event: MatrixEvent) => {
    console.log('messagesFetched', messagesFetched);
    console.log('isLoadMoreProcessing', isLoadMoreProcessing);

    if (!messagesFetched || isLoadMoreProcessing) return; // Ignore incoming messages until the initial fetch is done
    console.log('handleIncomingMessage');

    const content = event.getContent();

    const sender = event.getSender();

    const msgtype = content?.msgtype;
    const body = content?.body || '';
    const url = constructMediaUrl(content?.url || '');
    const type = sender === client?.getUserId() ? 'sent' : 'received';

    const eventId = event?.getId();
    const roomId = event?.getRoomId(); // Get the room ID from the event
    const isSelectedRoom = roomId === selectedRoom?.roomId;
    if (type === 'received') updateRoomReadStatus();
    try {
      if (isSelectedRoom && type === 'received') {
        await client?.sendReadReceipt(event, ReceiptType.Read);
      }
    } catch (error) {
      console.error('Failed to send receipt:', error);
    }

    if (event?.isRedacted()) {
      // Handle message redaction
      setMessages((prevMessages) =>
        prevMessages.map((msgGroup) => ({
          ...msgGroup,
          messages: msgGroup.messages.map((msg) =>
            eventId && msg.eventId === eventId && msg?.roomId && selectedRoom?.roomId
              ? { ...msg, body: 'This message has been deleted', isDeleted: true }
              : msg
          )
        }))
      );
    } else if (event?.getType() === 'm.room.message') {
      // Fetch user profile for the sender
      const userProfile = await fetchUserProfile(client as MatrixClient, sender as string);
      const { avatarUrl, displayName, presence } = userProfile || {};
      const room = client?.getRoom(selectedRoom?.roomId);
      const readBy = await getReadByUsers(room as Room, event, client as MatrixClient);

      setMessages((prevMessages) => {
        // Check if the last message in the array has the same roomId
        // Check if the last message group exists and has messages
        const lastGroup = prevMessages[prevMessages.length - 1]; // Get the last group of messages
        const prevRoomId =
          lastGroup && lastGroup.messages.length > 0
            ? lastGroup.messages[lastGroup.messages.length - 1].roomId
            : null;

        if (prevRoomId === roomId) {
          // If the last group has the same roomId, append the new message to it
          return prevMessages.map(
            (msgGroup, index) =>
              index === prevMessages.length - 1
                ? {
                    date: 'Today', // You might want to change this to the correct date
                    messages: [
                      ...msgGroup.messages,
                      {
                        event,
                        body,
                        msgtype,
                        url,
                        type,
                        isDeleted: false,
                        eventId,
                        roomId,
                        avatarUrl,
                        displayName,
                        presence,
                        readBy,
                        timestamp: event.getTs()
                      } as MessageData
                    ] // Append new message
                  }
                : msgGroup // Return the rest of the message groups unchanged
          ) as Messages[];
        }

        // Return previous messages unchanged if roomId doesn't match
        return prevMessages;
      });

      // Update lastMessageId if necessary
      if (!lastMessageId) {
        setLastMessageId(eventId as string);
      }
    }
  };

  const handleReadReceipts = (event: MatrixEvent) => {
    const content = event.getContent();
    for (const eventId in content) {
      const readReceipt = content[eventId]['m.read'];
      if (readReceipt) {
        for (const user in readReceipt) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          // console.log(
          //   selectedRoom?.userId,
          //   `Message  ====read by: ${user}, Timestamp: ${receipt.ts}`
          // );
          updateMessageReadStatus(eventId, user);
        }
      }
    }
  };

  const updateRoomReadStatus = async () => {
    if (client) {
      fetchRooms(client);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateMessageReadStatus = (eventId: string, userId: string) => {
    // Send the read receipt to the Matrix server

    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msgGroup) => ({
        date: msgGroup.date,
        messages: msgGroup?.messages.map((msg) => {
          if (msg.eventId === eventId) {
            console.log(`Message with ID ${eventId} read by ${userId}`);
            return { ...msg }; // Update the isRead property to true
          }
          return msg;
        })
      }));

      return updatedMessages;
    });
  };

  const handleSendMessage = async () => {
    if (message.trim() && selectedRoom && client) {
      try {
        // Get the room instance
        const room = client?.getRoom(selectedRoom.roomId);

        if (room) {
          // Send the message
          await client?.sendEvent(
            selectedRoom?.roomId, // Room ID
            'm.room.message' as string, // Event type
            { body: message, msgtype: 'm.text' as MsgType.Text } // Event content
          );

          setMessage(''); // Clear input after sending
        } else {
          console.error('Room not found');
          setMessage(''); // Clear input after sending
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  // Send message on enter
  const sendMessageOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };
  // Function to initialize Matrix client
  const initializeMatrixClient = async () => {
    const matrixClient = createClient({
      baseUrl: MATRIX_SERVER_URL,
      accessToken: token as string,
      deviceId: deviceId as string,
      userId: userId as string
    });
    try {
      restart(matrixClient);
      matrixClient.once('sync' as EmittedEvents, (state: SyncState) => {
        if (state === SyncState.Prepared) {
          fetchRooms(matrixClient);
          fetchPublicRooms(matrixClient, searchQuery);
          fetchInvitations(matrixClient);
          setLoading(false);
        }
      });

      // Listen for typing changes
      matrixClient.on(RoomMemberEvent.Typing, (event, member) => {
        setTypingPerson(
          member.typing ? `${member.name} is typing...` : `${member.name} stopped typing.`
        );
      });

      setClient(matrixClient);
    } catch (error) {
      setLoading(false);
      console.error('Error initializing Matrix client:', error);
    }
  };

  const openChat = useCallback(
    async (room: RoomData) => {
      setLoadMoreProcessing(false);
      setMessagesFetched(false);
      setLoading(false);

      setMessages([]);
      setPublicRooms([]);
      setSelectedRoom(room);
    },
    [client]
  );

  const updateCanLoadMore = (roomId: string, canLoad: boolean) => {
    setLoadState((prevState) => ({
      ...prevState,
      [roomId]: canLoad
    }));
  };

  const loadMore = () => {
    setIsLoadingEarlier(true);
    setLoadMoreProcessing(true);

    try {
      loadOlderMessages(
        setLastMessageId,
        setMessages,
        updateCanLoadMore,
        setIsLoadingEarlier,
        client,
        selectedRoom as RoomData,
        timeline
      );
    } catch (error) {
      console.error('Error loading earlier messages:', error);
    }
  };

  const handleJoinRoom = async (roomId: string) => {
    if (!client) {
      console.error('Matrix client is not initialized.');
      return;
    }

    try {
      const room = client.getRoom(roomId);
      const membership = room?.getMyMembership();
      const isUserId = roomId.startsWith('@');

      if (room && membership === 'join') {
        showAlert('error', t('error.alreadyJoinedRoom'));
        return;
      }

      if (isUserId) {
        await handleDirectMessageRoom(client, roomId, setAlert, t('success.joinRoom'), openChat);
      } else if (!room) {
        await joinRoomById(client, roomId, setAlert, t('success.joinRoom'), openChat);
      } else {
        await joinRoomById(client, roomId, setAlert, t('success.joinRoom'), openChat);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // Check if error is due to no servers being available for the room
      if (error?.message?.includes('no servers that are in the room')) {
        console.error(`Room ${roomId} has no servers available. Removing from invitations.`);
        // Remove the room from the invitations list (assumes you have an invitations state)
        await client.leave(roomId); // Leave the room to update status
        restart(client);
      }

      handleError(error);
    } finally {
      updateRoomsAndInvitations();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    console.error('Error joining room:', error);
    setAlert({ open: true, severity: 'error', message: t('error.couldNotJoinRoom') });
  };

  const updateRoomsAndInvitations = () => {
    fetchRooms(client as MatrixClient);
    fetchPublicRooms(client as MatrixClient, searchQuery);
    fetchInvitations(client as MatrixClient);
    restart(client);
  };

  const showAlert = (severity: 'success' | 'error', message: string) => {
    setAlert({ open: true, severity, message });
  };

  const handleCloseSnackbar = () => {
    setAlert((prevAlert) => ({ ...prevAlert, open: false }));
  };

  return {
    client,
    loading,
    chatLoading,
    showInvitations,
    showRooms,
    selectedRoom,
    showPersons,
    invitations,
    rooms,
    persons,
    filteredPersons,
    messages,
    lastMessageId,
    message,
    isCalling,
    isCallConnected,
    callTime,
    isMuted,
    incomingCall,
    activeCall,
    loadState,
    filteredRooms,
    alert,
    publicRooms,
    handleCloseSnackbar,
    setClient,
    setLoading,
    setShowInvitations,
    setShowRooms,
    setSelectedRoom,
    setShowPersons,
    setInvitations,
    setMessages,
    setLastMessageId,
    setMessage,
    setIsCalling,
    setCallConnected,
    setCallTime,
    setIsMuted,
    setIncomingCall,
    setActiveCall,
    loadMore,
    openChat,
    setLoadState,
    setFilteredRooms,
    searchQuery,
    setSearchQuery,
    handleJoinRoom,
    typingPerson,
    setAlert,
    sendMessageOnEnter,
    handleSendMessage,
    isLoadingEarlier,
    loadMoreRef: isLoadMoreProcessing
  };
};

export default useMatrix;
