import { MatrixClient, EventTimeline, MatrixEvent, Room, EventType } from 'matrix-js-sdk';
import { constructMediaUrl, fetchUserProfile, getReadByUsers } from '.';
import { SetMessagesFunction, RoomData, MessageData } from '../types';
import { formatDate } from '../../../utils';

export const loadOlderMessages = async (
  setLastMessageId: (arg: string) => void,
  setMessages: SetMessagesFunction,
  updateCanLoadMore: (roomId: string, canLoad: boolean) => void,
  setIsLoadingEarlier: (arg: boolean) => void,
  client: MatrixClient | null,
  selectedRoom: RoomData,
  timeline: { current: EventTimeline | null }
) => {
  try {
    setIsLoadingEarlier(true);
    const room = client?.getRoom(selectedRoom.roomId) as Room;

    if (!room) {
      console.warn(`Room with ID ${selectedRoom.roomId} does not exist.`);
      return;
    }
    if (room) {
      // Get the live timeline for the room
      timeline.current = room.getLiveTimeline();

      // Use paginateEventTimeline to fetch messages
      const canPaginate = await client?.paginateEventTimeline(timeline?.current as EventTimeline, {
        backwards: true,
        limit: 10 // Limit the number of messages fetched to 10
      });
      updateCanLoadMore(selectedRoom.roomId, canPaginate as boolean);

      if (canPaginate) {
        const events = timeline.current.getEvents();

        // Process and format the messages
        const formattedMessages = await Promise.all(
          events
            .filter((event: MatrixEvent) => event.getRoomId() === selectedRoom.roomId)
            .map(async (event: MatrixEvent) => {
              const eventId = event.getId();
              const roomId = event.getRoomId();
              const timestamp = event.getTs();
              const readBy = await getReadByUsers(room, event, client as MatrixClient);

              // Handle redacted (deleted) messages
              if (event.isRedacted()) {
                return {
                  event,
                  body: 'This message has been deleted',
                  msgtype: 'm.room.message',
                  isDeleted: true,
                  type: event.getSender() === client?.getUserId() ? 'sent' : 'received',
                  eventId,
                  roomId,
                  timestamp,
                  readBy
                };
              }

              const eventType = event.getType();
              const content = event.getContent();
              const sender = event.getSender();
              const type = sender === client?.getUserId() ? 'sent' : 'received';

              // Fetch user profile for the sender
              const userProfile = await fetchUserProfile(client as MatrixClient, sender as string);
              const { avatarUrl, displayName, presence } = userProfile || {};

              // Handle message events
              if (eventType === EventType.RoomMessage) {
                const msgtype = content?.msgtype;
                const body = content?.body || '';
                const url = constructMediaUrl(content?.url || '');

                return {
                  event,
                  body,
                  msgtype,
                  url,
                  type,
                  isDeleted: false,
                  eventId,
                  roomId,
                  avatarUrl,
                  displayName,
                  presence,
                  timestamp,
                  readBy
                };
              }

              // Handle encrypted messages
              if (eventType === EventType.RoomMessageEncrypted) {
                return {
                  event,
                  body: 'This message is encrypted and cannot be displayed',
                  msgtype: 'm.room.encrypted',
                  type,
                  isDeleted: false,
                  eventId,
                  avatarUrl,
                  displayName,
                  presence,
                  timestamp,
                  readBy
                };
              }

              // Handle member events
              if (eventType === EventType.RoomMember) {
                const memberState = content?.membership; // "join", "leave", or "invite"
                const memberName = content?.displayname || sender; // Use display name or sender ID

                let body = '';

                if (memberState === 'join') {
                  body = `${memberName} has joined the room.`;
                } else if (memberState === 'leave') {
                  body = `${memberName} has left the room.`;
                } else if (memberState === 'invite') {
                  body = `${memberName} has been invited.`;
                }

                return {
                  event,
                  body,
                  msgtype: 'm.room.member',
                  type: 'notification',
                  isDeleted: false,
                  eventId,
                  roomId,
                  avatarUrl,
                  displayName,
                  presence,
                  timestamp,
                  readBy // Include read receipts if needed
                };
              }

              return null; // Return null for unsupported event types
            })
        );

        const filteredMessages = formattedMessages.filter(Boolean) as unknown as MessageData[];

        // Sort messages by timestamp
        filteredMessages.sort((a, b) => a.timestamp - b.timestamp);

        // Group messages by date
        const messagesByDate: Record<string, MessageData[]> = {};

        filteredMessages.forEach((message) => {
          const dateKey = formatDate(message.timestamp);
          if (!messagesByDate[dateKey]) {
            messagesByDate[dateKey] = [];
          }
          messagesByDate[dateKey].push(message);
        });

        // Convert the grouped messages into a structured array
        const structuredMessages = Object.entries(messagesByDate).map(([date, messages]) => ({
          date,
          messages
        }));

        setMessages(structuredMessages); // Update state with structured messages
        console.log('structuredMessages in load more', structuredMessages);

        // Set the last message ID to the oldest message
        if (filteredMessages.length > 0) {
          setLastMessageId(filteredMessages[filteredMessages.length - 1].eventId as string); // Oldest message
        }
      }

      setIsLoadingEarlier(false);
    }
  } catch (error) {
    console.error('Error fetching messages:', error);
    setIsLoadingEarlier(false); // Ensure loading state is reset on error
  }
};
