import { MatrixClient, MsgType } from 'matrix-js-sdk';
import { RoomData } from '../types';

// Function to validate the file type
const validateFileType = (fileType: string): boolean => {
  const allowedTypes = [
    'audio/mpeg', // MP3
    'audio/wav', // WAV
    'application/pdf', // PDF
    'application/msword', // DOC
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
    'image/jpeg', // JPEG
    'image/png', // PNG
    'image/gif' // GIF
    // Add any other allowed MIME types as needed
  ];

  return allowedTypes.includes(fileType);
};

const handleFileChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  client: MatrixClient,
  selectedRoom: RoomData,
  setAlertMessage: (arg: string) => void
) => {
  const file = event.target.files?.[0];

  if (file) {
    const isValidFileType = validateFileType(file.type);
    const isValidFileSize = file.size <= 10 * 1024 * 1024; // 10 MB in bytes

    if (!isValidFileType) {
      setAlertMessage('error.invalidFile');
      return;
    }

    if (!isValidFileSize) {
      setAlertMessage('error.invalidFileSize');
      return;
    }

    // Proceed with file upload if validations pass
    await handleFileUpload(file, client, selectedRoom);
  }
};
const handleFileUpload = async (
  file: File | undefined,
  client: MatrixClient | null,
  selectedRoom: RoomData
) => {
  if (client && selectedRoom?.roomId && file) {
    try {
      // Upload the file
      const response = await client.uploadContent(file, {
        name: file.name,
        type: file.type
      });

      const fileUrl = response.content_uri;

      // Determine the msgtype and update `isMedia` accordingly
      let msgtype: string;
      if (file.type.startsWith('image/')) {
        msgtype = 'm.image';
      } else if (file.type.startsWith('video/')) {
        msgtype = 'm.video';
      } else if (file.type.startsWith('audio/')) {
        msgtype = 'm.audio';
      } else if (file.type === 'application/pdf') {
        msgtype = 'm.file';
      } else {
        msgtype = 'm.file'; // Fallback for other file types
      }

      // Send the message with the file URL
      await client.sendEvent(selectedRoom.roomId, 'm.room.message' as string, {
        body: file.name,
        msgtype: msgtype as MsgType.Video,
        url: fileUrl
      });

      console.log('File uploaded and message sent successfully');
    } catch (error) {
      console.error('Error uploading file or sending message:', error);
    }
  }
};

export { handleFileUpload, handleFileChange };
