import { useState, useCallback } from 'react';
import { MatrixClient } from 'matrix-js-sdk'; // Adjust the import as necessary
import { restart } from '../pages/Chat/helper';
import { RoomData } from '../pages/Chat/types';

export const useFetchInvitations = () => {
  const [invitations, setInvitations] = useState<RoomData[]>([]); // State to hold valid invitations

  const fetchInvitations = useCallback(async (client: MatrixClient) => {
    try {
      const rooms = client.getRooms();
      const validInvitations = rooms
        .filter((room) => {
          const membership = room.getMyMembership();

          // Check if the room membership is still an invite
          if (membership !== 'invite') {
            return false;
          }

          try {
            // Check if the room has any available servers or members
            const joinedMembers = room.getJoinedMembers();

            // If no members are found, we assume the room is no longer valid
            if (joinedMembers.length === 0) {
              console.warn(`Room ${room.roomId} has no available servers or members.`);
              return false; // Exclude this room
            }

            return true; // Keep this room since it has members
          } catch (error) {
            console.error(`Error checking room ${room.roomId}:`, error);
            return false; // Exclude the room on error
          }
        })
        .map((room) => ({
          roomId: room.roomId,
          name: room.name || 'Untitled Room'
        }));

      setInvitations(validInvitations);
      restart(client);
    } catch (error) {
      console.error('Error fetching invitations:', error);
    }
  }, []);
  return { invitations, setInvitations, fetchInvitations }; // Return invitations and fetch function
};

export default useFetchInvitations;
