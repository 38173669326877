import { useState, useCallback } from 'react';
import { MatrixClient } from 'matrix-js-sdk';
import { getRooms, getDmsPersons } from '../pages/Chat/helper';
import { RoomData } from '../pages/Chat/types';

export const useFetchRooms = () => {
  const [rooms, setRooms] = useState<RoomData[]>([]);
  const [persons, setPersons] = useState<RoomData[]>([]);

  const fetchRooms = useCallback(async (client: MatrixClient) => {
    try {
      // Get rooms joined by the user
      const allRooms = getRooms(client);

      // Map rooms with unread notification counts
      const roomWithUnreadCounts = allRooms.map((room) => ({
        ...room,
        unreadCount: room.getUnreadNotificationCount(),
        isDm: false
      }));

      // Only update rooms if there are changes
      setRooms((prevRooms) => {
        const hasRoomChanged = roomWithUnreadCounts.some((newRoom, index) => {
          const prevRoom = prevRooms[index];
          return (
            prevRoom?.roomId !== newRoom.roomId || prevRoom?.unreadCount !== newRoom.unreadCount
          );
        });
        return hasRoomChanged ? roomWithUnreadCounts : prevRooms;
      });

      // Fetch direct message persons
      const allPersons = await getDmsPersons(client);

      // Map persons with unread counts based on room association
      const personsWithUnreadCounts = allPersons.map((person) => ({
        ...person,
        isDm: true,
        unreadCount: client.getRoom(person?.roomId)?.getUnreadNotificationCount()
      }));

      // Only update persons if there are changes
      setPersons((prevPersons) => {
        const hasPersonChanged = personsWithUnreadCounts.some((newPerson, index) => {
          const prevPerson = prevPersons[index];
          return (
            prevPerson?.roomId !== newPerson.roomId ||
            prevPerson?.unreadCount !== newPerson.unreadCount
          );
        });
        return hasPersonChanged ? personsWithUnreadCounts : prevPersons;
      });
    } catch (error) {
      console.error('Error fetching rooms or persons:', error);
    }
  }, []); // Make sure client and userId are stable

  const fetchSelectedRoom = useCallback(async (client: MatrixClient, selectedRoomId: string) => {
    try {
      // Only update the state if the selected room's unread count changed
      setRooms((prevRooms) => {
        const newRooms = prevRooms.map((room) => {
          if (room.roomId === selectedRoomId) {
            // Only update the unread count for the selected room
            return {
              ...room,
              unreadCount: client.getRoom(room.roomId)?.getUnreadNotificationCount() || 0
            };
          }
          // Return other rooms unchanged
          return {
            ...room,
            unreadCount: room.unreadCount
          };
        });
        return newRooms;
      });
      // Only update the state if the selected room's unread count changed
      setPersons((prevRooms) => {
        const newPersons = prevRooms.map((room) => {
          if (room.roomId === selectedRoomId) {
            // Only update the unread count for the selected room
            return {
              ...room,
              unreadCount: client.getRoom(room.roomId)?.getUnreadNotificationCount() || 0
            };
          }
          // Return other rooms unchanged
          return {
            ...room,
            unreadCount: room.unreadCount
          };
        });
        return newPersons;
      });
    } catch (error) {
      console.error('Error fetching rooms or persons:', error);
    }
  }, []);

  return { rooms, persons, fetchRooms, fetchSelectedRoom };
};

export default useFetchRooms;
