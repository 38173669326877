import { MatrixClient } from 'matrix-js-sdk';
import { constructMediaUrl } from '.';

const getPublicRooms = async (client: MatrixClient | null, searchQuery: string) => {
  // Step 1: Fetch public rooms based on search query
  const publicRoomsResponse = await client?.publicRooms({
    filter: {
      generic_search_term: searchQuery
    }
  });

  const publicRooms = publicRoomsResponse?.chunk
    .filter((room) => !client?.getRoom(room.room_id)) // Filter out rooms the user has already joined
    .map((room) => {
      const roomObject = client?.getRoom(room.room_id);
      return {
        roomId: room.room_id,
        name: room.name || 'Unnamed Public Room',
        avatarUrl: room?.avatar_url ? constructMediaUrl(room.avatar_url) : null,
        isJoined: roomObject?.getMyMembership() === 'join' // Check membership status
      };
    })
    .filter((room) => room.isJoined !== true); // Filter out joined rooms

  // Step 2: Search the Matrix user directory
  const searchResponse = await client?.searchUserDirectory({ term: searchQuery });
  // Step 3: Get direct chats from joined rooms
  const joinedRooms = client
    ?.getRooms()
    ?.filter((room) => room?.getMyMembership() === 'join' || room?.getMyMembership() === 'invite');

  const directChatRooms = joinedRooms?.filter((room) => {
    const directAccountData = room.getAccountData('m.direct');
    return directAccountData && Object.keys(directAccountData.getContent()).length > 0;
  });

  // Extract user IDs of people with whom you already have direct chats
  const directChatUserIds = new Set(
    directChatRooms?.flatMap((room) => room.getJoinedMembers().map((member) => member.userId))
  );

  // Step 4: Filter out users you already have direct chats with
  const nonJoinedUsers = searchResponse?.results
    .filter((user) => !directChatUserIds.has(user.user_id))
    .map((user) => ({
      roomId: user.user_id,
      avatarUrl: user?.avatar_url ? constructMediaUrl(user.avatar_url) : null,
      name: user.display_name || '' // Fallback to user_id if no display name
    }));

  // Step 5: Combine and set public rooms and users

  return [...(publicRooms || []), ...(nonJoinedUsers || [])];
};

export { getPublicRooms };
