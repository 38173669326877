// selectors.ts
import { createSelector } from 'reselect';
import { MatrixState, RootState } from 'data'; // Update with your actual import paths

// Base selector to get the matrix state
const matrixSelector = (state: RootState) => state.matrix;

/**
 * Helper function to create memoized selectors for different fields
 * @param field - The specific field of the Matrix state to select
 * @returns A memoized selector for the specified field
 */
const createMatrixSelector = <T extends keyof MatrixState>(field: T) =>
  createSelector([matrixSelector], (matrixState): MatrixState[T] => matrixState[field]);

// Memoized selectors for various fields in the Matrix state
export const selectMatrixList = createMatrixSelector('list');
export const selectMatrixLoading = createMatrixSelector('loading');
export const selectMatrixError = createMatrixSelector('error');
