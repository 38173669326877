// Helper function to format time to "hh:mm AM/PM"
const formatTime = (timestamp: number) => {
  const date = new Date(timestamp);
  const options = { hour: '2-digit', minute: '2-digit', hour12: true } as const;
  return date.toLocaleTimeString('en-US', options);
};

// Helper function to format date like WhatsApp (Today, Yesterday, or '5 Oct')
const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set today's date to midnight
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1); // Set yesterday's date to midnight

  if (date >= today) {
    return 'Today';
  } else if (date >= yesterday) {
    return 'Yesterday';
  } else {
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }); // Format to '5 Oct'
  }
};

export { formatDate, formatTime };
