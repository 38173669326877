import { MatrixClient } from 'matrix-js-sdk';
import { RoomData, SetPersonRoomsFunction } from '../pages/Chat/types';
import { useState, useEffect } from 'react';
import { getPublicRooms } from '../pages/Chat/helper';

export const useFilteredRooms = (
  client: MatrixClient | null,
  setPublicRooms: SetPersonRoomsFunction,
  searchQuery: string,
  rooms: RoomData[],
  persons: RoomData[]
) => {
  const [filteredRooms, setFilteredRooms] = useState<RoomData[]>(rooms);
  const [filteredPersons, setFilteredPersons] = useState<RoomData[]>(persons);

  // Filter rooms based on search query
  useEffect(() => {
    const fetchFilteredRooms = async () => {
      if (searchQuery.trim() === '') {
        setFilteredRooms(rooms); // Show all rooms if no query
        setFilteredPersons(persons);
        setPublicRooms([]);
      } else {
        const lowerCaseQuery = searchQuery.toLowerCase();

        const filteredRoomsData = rooms?.filter((room) =>
          room.name.toLowerCase().includes(lowerCaseQuery)
        );
        setFilteredRooms(filteredRoomsData);

        const filteredPersonsData = persons?.filter((person) =>
          person.name.toLowerCase().includes(lowerCaseQuery)
        );

        setFilteredPersons(filteredPersonsData);

        const publicData = await getPublicRooms(client, searchQuery);
        const filteredData = publicData.filter(
          (publicItem) =>
            !rooms.some((room) => room.name === publicItem.name) &&
            !persons.some((room) => room.name === publicItem.name)
          // Exclude matching names
        );
        setPublicRooms(filteredData as RoomData[]);
      }
    };

    fetchFilteredRooms();
  }, [searchQuery, rooms, client]);

  return { filteredRooms, filteredPersons, setFilteredRooms };
};

export default useFilteredRooms;
