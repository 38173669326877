import { MatrixClient } from 'matrix-js-sdk';
import { constructMediaUrl } from '.';

export const fetchUserProfile = async (client: MatrixClient, userId: string) => {
  try {
    // Fetch user profile info (e.g., avatar, display name)
    const profile = await client.getProfileInfo(userId);

    // Fetch user presence (online/offline status)
    const presenceEvent = await client.getPresence(userId);

    const avatarUrl = profile?.avatar_url ? constructMediaUrl(profile.avatar_url) : null;
    const displayName = profile?.displayname || userId;
    const presence = presenceEvent?.presence || 'offline'; // Default to 'offline' if not available

    return { avatarUrl, displayName, presence };
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }
};
