import {
  IconCheck,
  IconChecks,
  IconChevronDown,
  IconChevronRight,
  IconDownload,
  IconFolderOpen,
  IconLoader,
  IconPaperclip,
  IconPlus,
  IconRefresh
} from '@tabler/icons-react';
import { ICON_TYPE } from '../constants/icons';

const iconHandler = (
  name: string,
  color: string,
  stroke: number,
  size: number,
  className?: string
) => {
  switch (name) {
    case ICON_TYPE.PLUS:
      return <IconPlus size={size} stroke={stroke} color={color} />;
    case ICON_TYPE.ARROW_DOWN:
      return <IconChevronDown size={size} stroke={stroke} color={color} />;

    case ICON_TYPE.ARROW_RIGHT:
      return <IconChevronRight size={size} stroke={stroke} color={color} />;

    case ICON_TYPE.SINGLE_TICK:
      return <IconCheck size={size} stroke={stroke} color={color} />;

    case ICON_TYPE.DOUBLE_TICK:
      return <IconChecks size={size} stroke={stroke} color={color} />;

    case ICON_TYPE.DOWNLOAD:
      return <IconDownload size={size} stroke={stroke} color={color} />;

    case ICON_TYPE.ATTACHMENT:
      return <IconPaperclip size={size} stroke={stroke} color={color} />;

    case ICON_TYPE.LOADING:
      return <IconLoader size={size} stroke={stroke} color={color} className={className} />;

    case ICON_TYPE.REFRESH:
      return <IconRefresh size={size} stroke={stroke} color={color} />;
    default:
      return <IconFolderOpen size={size} stroke={stroke} color={color} />;
  }
};

export { iconHandler };
