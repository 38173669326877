export const ICON_TYPE = {
  PLUS: 'plus',
  ARROW_DOWN: 'arrow-down',
  ARROW_RIGHT: 'arrow-right',
  DOUBLE_TICK: 'double-tick',
  SINGLE_TICK: 'single-tick',
  DOWNLOAD: 'download',
  ATTACHMENT: 'attachment',
  LOADING: 'loading',
  REFRESH: 'refresh'
};
