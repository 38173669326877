import { MatrixClient } from 'matrix-js-sdk';

export const restart = async (client: MatrixClient | null) => {
  if (client) {
    try {
      await client.startClient({ initialSyncLimit: 20 });
    } catch (error) {
      console.error('Error starting Matrix client:', error);
    }
  } else {
    console.error('Matrix client is not initialized.');
  }
};
