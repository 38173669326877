import { Room, MatrixEvent, MatrixClient } from 'matrix-js-sdk';
import { fetchUserProfile } from './fetchUserProfile';

const getReadByUsers = async (room: Room, event: MatrixEvent, client: MatrixClient) => {
  // Get the read receipts for the specified event
  const receipts = room.getReceiptsForEvent(event);

  // Extract user IDs from the receipts
  const readByUsers = receipts.map((receipt) => receipt.userId);

  // Fetch the profile details (avatars) for each user who read the message
  const userProfiles = await Promise.all(
    readByUsers.map(async (userId) => {
      const profile = await fetchUserProfile(client, userId);
      return {
        userId,
        displayName: profile?.displayName || userId,
        avatarUrl: profile?.avatarUrl || null
      };
    })
  );

  return userProfiles;
};

export { getReadByUsers };
