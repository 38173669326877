import { AlertType } from 'data';
import { IContent, MatrixClient, Preset, Visibility } from 'matrix-js-sdk';
import { RoomData } from '../types';
import { restart } from './restart';
import { MATRIX_SERVER_URL } from '../url';

export const setDirectMessage = async (client: MatrixClient, accountData: IContent) => {
  try {
    await client.setAccountData('m.direct', accountData);
  } catch (error) {
    console.error('Error setting m.direct:', error);
  }
};

const sendInitialMessage = async (client: MatrixClient, roomId: string) => {
  await client.sendMessage(roomId, {
    msgtype: 'm.text',
    body: `Hello, you have a new message from ${client.getUserId()}.`
  });
};

const updateDirectMessages = async (client: MatrixClient, roomId: string, newRoomId: string) => {
  const accountData = client.getAccountData('m.direct')?.getContent() || {};

  if (!accountData[roomId]) {
    accountData[roomId] = [];
  }

  if (!accountData[roomId].includes(newRoomId)) {
    accountData[roomId].push(newRoomId);
  }

  await setDirectMessage(client, accountData);
  console.log('Updated m.direct with room ID:', newRoomId);
};

const handleDirectMessageRoom = async (
  client: MatrixClient,
  roomId: string,
  setAlert: (arg: AlertType) => void,
  msg: string,
  openChat: (arg: RoomData) => void
) => {
  console.log('Checking if a DM room already exists for user:', roomId);

  // Get all the rooms the client is part of
  const rooms = client.getRooms();

  // Find a room that is marked as a direct message and includes the given userId
  const existingDMRoom = rooms.find((room) => {
    const dmMembers = room.getAccountData('m.direct')?.getContent();
    return dmMembers && dmMembers.includes(roomId);
  });

  if (existingDMRoom) {
    console.log('Found an existing DM room:', existingDMRoom.roomId);
    await client.joinRoom(existingDMRoom.roomId);
    setAlert({ open: true, severity: 'info', message: 'Opened existing chat.' });
    const updatedRoom = {
      ...existingDMRoom,
      isDm: true
    };
    openChat(updatedRoom);
  } else {
    console.log('No existing DM room found. Creating a new DM room for user:', roomId);
    const createdRoom = await client.createRoom({
      preset: Preset.TrustedPrivateChat,
      visibility: Visibility.Private,
      invite: [roomId],
      is_direct: true
    });

    console.log('Created Room ID:', createdRoom.room_id);
    await sendInitialMessage(client, createdRoom.room_id);
    await client.joinRoom(createdRoom.room_id);
    const roomData = client.getRoom(createdRoom.room_id);
    const updatedRoom = {
      avatarUrl: roomData?.getAvatarUrl(MATRIX_SERVER_URL, 30, 30, 'scale'),
      roomId: roomData?.roomId,
      name: roomData?.name,
      isDm: true
    };

    setAlert({ open: true, severity: 'success', message: msg });
    openChat(updatedRoom as RoomData);

    await updateDirectMessages(client, roomId, createdRoom.room_id);
  }
};

const joinRoomById = async (
  client: MatrixClient,
  roomId: string,
  setAlert: (arg: AlertType) => void,
  msg: string,
  setSelectedRoom: (arg: RoomData) => void
) => {
  await client.joinRoom(roomId);
  console.log('roomIdroomIdroomId', roomId);
  restart(client);
  const room = client.getRoom(roomId);
  const updatedRoom = {
    ...room,
    isDm: false
  };
  console.log('room', room);
  setSelectedRoom(updatedRoom as RoomData);
  setAlert({ open: true, severity: 'success', message: msg });
};

export { sendInitialMessage, updateDirectMessages, handleDirectMessageRoom, joinRoomById };
