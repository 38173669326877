import { MatrixClient } from 'matrix-js-sdk';

export const getRooms = (client: MatrixClient | null) => {
  // Fetch all rooms and filter for joined rooms only (membership 'join')
  const rooms = client?.getRooms();
  if (!rooms || !client) return [];

  // Get the m.direct account data, which contains all direct message rooms
  const directChats = client.getAccountData('m.direct');
  const directRoomIds = new Set<string>();

  if (directChats) {
    // Get all DM room IDs from the direct chat data
    Object.values(directChats.getContent()).forEach((roomIds) => {
      if (Array.isArray(roomIds)) {
        roomIds.forEach((roomId) => directRoomIds.add(roomId));
      }
    });
  }

  // Filter out rooms that are direct messages (DMs) and not joined
  const joinedRooms = rooms.filter(
    (room) =>
      room.getMyMembership() === 'join' && // Joined room
      !room.isSpaceRoom() && // Not a Space room
      !directRoomIds.has(room.roomId) // Exclude DM rooms
  );

  // Get unique rooms by their room ID
  const uniqueRooms = Array.from(new Map(joinedRooms.map((room) => [room.roomId, room])).values());

  return uniqueRooms;
};
